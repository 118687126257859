<template>
  <v-container
    fluid
  >
    <ocorrencias-listar-filtros
      v-model="filtros"
      :mostrar="dialogFiltros"
      @cancelar="dialogFiltros = false"
      @filtrar="carregarOcorrencias"
    />

    <v-row
      class="mt-1"
    >
      <v-col>
        <btn-adicionar
          v-if="$temPermissao('ocorrencia-insercao')"
          class="mr-2 mt-2 mt-md-0"
          to="/ocorrencias/adicionar"
        />
        <btn-filtros
          class="mr-2 mt-2 mt-md-0"
          @click="dialogFiltros = true"
        />
        <v-btn
          v-if="$temPermissao('ocorrencia-insercao')"
          class="mt-2 mt-md-0"
          color="red"
          @click="mostrarImportarPdf = true"
        >
          <v-icon
            left
            v-text="'mdi-file-pdf'"
          />
          Importar PDF
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      v-if="$temPermissao('ocorrencia-listagem')"
      class="mt-4"
    >
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            disable-sort
            :server-items-length="totalRegistros"
            :loading="carregando"
            :headers="cabecalho"
            :items="ocorrencias"
            :options.sync="paginacao"
            :footer-props="{
              'items-per-page-options': [10, 20, 50]
            }"
            @update:options="carregarOcorrencias"
          >
            <template
              v-slot:[`item.data_ocorrencia`]="{ item }"
            >
              {{ item.data_ocorrencia | data }}
            </template>
            <template
              v-slot:[`item.veiculos`]="{ item }"
            >
              <v-chip
                v-for="veiculo in item.veiculos"
                :key="veiculo.id"
                small
                label
                class="mr-1 mt-1"
              >
                {{ veiculo.placa_veiculo }}
              </v-chip>
            </template>
            <template
              v-slot:[`item.pessoas`]="{ item }"
            >
              <v-chip
                v-for="pessoa in item.pessoas"
                :key="pessoa.id"
                small
                label
                class="mr-1 mt-1"
              >
                {{ pessoa.nome }}
              </v-chip>
            </template>
            <template
              v-slot:[`item.actions`]="{ item }"
              class="text-right"
            >
              <table-btn-editar
                v-if="$temPermissao('ocorrencia-visualizacao')"
                :href="`/ocorrencias/${item.id}/editar`"
              />
              <table-btn-see
                v-if="$temPermissao('ocorrencia-visualizacao')"
                :ocorrencia="item"
              />
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <ocorrencias-importar-pdf
      v-model="mostrarImportarPdf"
      @importado="carregarOcorrencias"
    />
  </v-container>

</template>

<script>
import ocorrenciasApi from '@/api/ocorrencias'

export default {

  components: {
    OcorrenciasListarFiltros: () => import('@/views/pages/ocorrencias/OcorrenciasListarFiltros'),
    OcorrenciasImportarPdf: () => import('@/components/dialog/ocorrencias/ImportarPdf'),
  },

  data () {
    return {
      carregando: false,
      dialogFiltros: false,
      totalRegistros: 0,
      cabecalho: [
        { text: '#', value: 'id' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Data', value: 'data_ocorrencia' },
        { text: 'Natureza', value: 'natureza_ocorrencia.descricao' },
        { text: 'Veiculos', value: 'veiculos' },
        { text: 'Pessoas', value: 'pessoas' },
        { text: 'Ações', value: 'actions', align: 'right' },
      ],
      ocorrencias: [],
      paginacao: {},
      filtros: { situacao_ocorrencia_id: 1, data_tipo: 'fato' },
      oldFiltros: { situacao_ocorrencia_id: 1, data_tipo: 'fato' },
      mostrarImportarPdf: false,
    }
  },

  methods: {
    filtersHasChanged() {
      let dataTipo = {
        new: this.filtros.data_tipo ?? null,
        old: this.oldFiltros.data_tipo ?? null
      };
      let dataInicio = {
        new: this.filtros.data_inicio ?? null,
        old: this.oldFiltros.data_inicio ?? null
      };
      let dataFim = {
        new: this.filtros.data_fim ?? null,
        old: this.oldFiltros.data_fim ?? null
      };
      let placaVeiculo = {
        new: this.filtros.placa_veiculo ?? null,
        old: this.oldFiltros.placa_veiculo ?? null
      };
      let visibilidade = {
        new: this.filtros.visibilidade_id ?? null,
        old: this.oldFiltros.visibilidade_id ?? null
      };
      let natureza = {
        new: this.filtros.natureza_ocorrencia_id ?? null,
        old: this.oldFiltros.natureza_ocorrencia_id ?? null
      };
      let situacaoOcorrencia = {
        new: this.filtros.situacao_ocorrencia_id ?? null,
        old: this.oldFiltros.situacao_ocorrencia_id ?? null
      };
      let cidade = {
        new: this.filtros.cidade_id ?? null,
        old: this.oldFiltros.cidade_id ?? null
      };

      return dataInicio.old !== dataInicio.new ||
        dataFim.old !== dataFim.new ||
        dataTipo.old !== dataTipo.new ||
        cidade.old !== cidade.new ||
        natureza.old !== natureza.new ||
        placaVeiculo.old !== placaVeiculo.new ||
        situacaoOcorrencia.old !== situacaoOcorrencia.new ||
        visibilidade.old !== visibilidade.new;
    },

    async carregarOcorrencias () {
      try {
        this.carregando = true
        this.dialogFiltros = false

        let filters = JSON.parse(JSON.stringify(this.filtros))
        if (this.filtersHasChanged() && this.paginacao.page !== 1) {
          this.paginacao.page = 1

          /**
           * Como o componente `v-data-table` está com um @update:options="carregarOcorrencias", ao alterar-mos um dos
           * paramentos do componente, como feito na linha acima (this.paginacao.page = 1), a função carregarOcorrencias()
           * será chamada novamente, então ao entrar neste IF o código não precisa continuar, pois assim estaríamos fazendo
           * duas chamadas iguais para a API
           */
          return;
        }

        const resposta = await ocorrenciasApi.listar({
          pagina: this.paginacao.page,
          por_pagina: this.paginacao.itemsPerPage,
          ...filters,
        })

        this.ocorrencias = resposta.data.data
        this.totalRegistros = resposta.data.meta.total
        this.oldFiltros = { ...this.filtros }
      } catch (e) {
        this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
      } finally {
        this.carregando = false
      }
    },
  },

  watch: {
    'filtros.placa_veiculo'(value) {
      this.filtros.placa_veiculo = this.filtros.placa_veiculo.toUpperCase()
    }
  }

}
</script>
